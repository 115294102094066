<template>
  <div class="container">
    <div>
      <content-title :nav="nav"></content-title>
    </div>
    <div class="ontents">
      <div class="sec-title">兑换码生成列表</div>
    </div>
    <div class="search">
      <el-form :inline="true" :model="form" class="demo-form-inline">
        <el-form-item label="用户名">
          <el-input size="small" v-model="name"></el-input>
        </el-form-item>
        <el-form-item label="账号">
          <el-input size="small" v-model="phone"></el-input>
        </el-form-item>
        <!-- <el-form-item label="锦鲤码">
          <el-input v-model="couponCode"></el-input>
        </el-form-item> -->
        <el-form-item>
          <el-button size="small" type="primary" @click="onSubmit()">查询</el-button>
        </el-form-item>
      </el-form>
      <div class="btnfafang" style="margin-bottom:1rem;float:right">
        <el-button type="danger" size="small" @click="isFlag">生成</el-button>
        <!-- <el-button type="danger" @click="drawCode" size="small">批量领取</el-button> -->
      </div>
    </div>
    <!-- 批量领取 -->

    <!-- 列表 -->
    <div>
      <textarea id="input" style="position:fixed;left:-100%"></textarea>

      <div class="contentList">
        <div class="paging">
          <el-table border ref="multipleTable" :header-cell-style="{ background: '#EAEAEA', color: '#434343' }"
            :data="tableData" tooltip-effect="dark" style="width: 100%" @filter-change="filterChange"
            @selection-change="selectiondrawCode">
            <el-table-column type="selection"></el-table-column>
            <el-table-column prop="code" align="center" width="200" label="兑换码"></el-table-column>
            <el-table-column prop="map.commodityCouponEntity.name" align="center" width="200" label="券名称">
            </el-table-column>
            <el-table-column prop="coupon_type" align="center" width="200" label="券类型"></el-table-column>
            <el-table-column prop="coupon_type" align="center" width="200" label="是否合作商券">
              <template slot-scope="scope">
                {{scope.row.map.commodityCouponEntity.platform == '合作商'?'是':'否'}}
              </template>
            </el-table-column>
            <el-table-column align="center" label="状态">
              <template slot-scope="{row}">
                <div  >
                  {{s_List[row.status-1]}}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="map.createUserInfo.user_id" align="center" width="200" label="生成用户">
            </el-table-column>
            <el-table-column prop="mobile" align="center" width="300" label="账号"></el-table-column>
            <el-table-column prop="create_time" align="center" width="300" label="生成时间"></el-table-column>
            <el-table-column align="center" label="操作" width="100">
              <template slot-scope="scope">
                <el-button @click="lingqu(scope.row)" type="text" size="small">兑换</el-button>
                <el-button @click="copyCode(scope.row)" type="text" size="small">复制</el-button>
                <el-button @click="lingqu(scope.row)" type="text" size="small">领取</el-button>

              </template>
            </el-table-column>
          </el-table>
          <!--分页-->
          <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page.sync="currentPage" :page-size="10" layout="prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
      </div>
      <input id="copy_content" type="text" value=""
        style="position: absolute; top: 0; left: 0; opacity: 0; z-index: -10" />
    </div>
    <!-- 生成锦鲤码 -->
    <el-dialog title="生成锦鲤码" :visible.sync="flag" width="30%" :before-close="handleClose">
      <div class="login">
        <div class="popupcontents">
          <el-form ref="form" label-width="100px" style="padding-right: 40px">
            <el-form-item label="选择券">
              <el-select v-model="coupon_id" placeholder="请选择券" remote @focus="getCoupon">
                <el-option v-for="(item, index) in coupon_list" :key="index" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
             <el-form-item label="发放用户账号">
              <el-input v-model="grantNum" type="phone" placeholder="请输入电话"></el-input>
              <span style="position: absolute; right: 40px"></span>
            </el-form-item>
            <el-form-item label="发放用户">
                <el-select v-model="selected_user" placeholder="请选择" value-key="user_id"  filterable
              remote :remote-method="getUserList">
              <el-option v-for="item in user_list" :key="item.user_id" :label="item.nick_name" :value="item">
              </el-option>
            </el-select>
            </el-form-item>
            <el-form-item label="发卡方">
              <el-select v-model="commodity_type" placeholder="请选择发卡方">
                <el-option label="机构" value="机构">
                </el-option>
                <el-option label="合作商" value="合作商">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="生成数量">
              <el-input v-model="grantNum" type="number" placeholder="请输入生成的数量"></el-input>
              <span style="position: absolute; right: 40px">条</span>
            </el-form-item>
            <el-form-item label="有效期">
              <el-input v-model="overdue" type="number" placeholder="请输入的有效期"></el-input>
              <span style="position: absolute; right: 40px">天</span>
            </el-form-item>
            <el-form-item>
              <el-button style="margin-left: 30px" @click="toclose">取消</el-button>
              <el-button style="margin-left: 110px" type="primary" @click="generateCoupon">确认</el-button>
            </el-form-item>
            <div class="btns"></div>
          </el-form>
        </div>
      </div>
    </el-dialog>
    <!-- 领取锦鲤码 -->
    <!-- <el-dialog
      title="确认领取锦鲤码"
      :visible.sync="show"
      width="30%"
      :before-close="handleClose"
    >
      <div class="login">
        <div style="margin: 20px 0">
          <el-button style="margin-left: 120px" @click="toclose"
            >取消</el-button
          >
          <el-button style="margin-left: 110px" type="primary" @click="lingqu"
            >确认</el-button
          >
        </div>
      </div>
    </el-dialog> -->
    <!-- 确认领取锦鲤码 -->
    <!-- <el-dialog
      title="领取锦鲤码"
      :visible.sync="codeShow"
      width="30%"
      :before-close="handleClose"
    >
      <div class="login">
        <textarea id="input" style="position:fixed;left:-100%"></textarea>
        <p style="font-size:2.5rem;text-align: center; color: #2f2f2f">
          {{ code }}
        </p>
      </div>
      <div style="margin: 20px 0">
        <el-button style="margin-left: 120px" @click="toclose">取消</el-button>
        <el-button style="margin-left: 110px" type="primary" @click="renew"
          >确认</el-button
        ><el-button type="text" style="margin-left:1.5rem" @click="copy()"
          >复制</el-button
        >
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
export default {
  name: 'LiveStreaming',
  components: {},
  data() {
    return {
      nav: {
        firstNav: '优惠券管理',
        secondNav: '生成兑换码',
      },
      tableData: [], //总数据
      flag: false,
      coupon_length: null,
      show: false,
      codeShow: false,
      code: '',
      id: [],
      start_num:null,
      user_id: '',
      total: 0,
      commodity_type: null,
      name: '',
      phone: '',
      coupon_list: [],
      coupon_id: null,
      couponCode: '',
      grantNum: '',
      overdue: '', //添加条数
      sels: [], //选中数据组

      form: {
        user: '',
        date1: '',
      },
      pageSize: 10,
      currentPage: 1,
      total: 0,
      dorw: false,
      selected_user:'',
      user_list:[],
      s_List:['被领取' ,'未领取' , '被查看' ,'过期']
    }
  },
  mounted() {
    // this.GetNumbers();
    // this.GetList()
    this.onSubmit()
    // this.qwe()
  },
  methods: {
     getUserList(query) {
      this.user_list = []
      if (query && typeof query == 'string') {
        let url = '/user/user/queryByMessage'
        this.$axios({
          url,
          params:{
              login_name: query
            },
          method:'get'
        }).then((res) => {
            this.user_list = res.data.data.rows
          })
      }
    },
    selectiondrawCode(selection) {
      //  console.log(selection,"当列数据111")
      this.sels = selection
    },

    renew() {
      this.codeShow = false
    },
    isFlag() {
      this.flag = true
      //  console.log("弹框",this.flag)
    },
    filterChange(filterObj) {
      //  console.log(filterObj, "当列数据");
    },
    handleSizeChange(val) {},
    handleCurrentChange(val) {
      //  console.log(`当前页: `, val);
      this.currentPage = val
      this.GetList()
    },
    copyCode(row) {
      //  console.log(row);
      this.id = []
      this.code = row.coupon_code
      this.id.push(row.id)
      this.user_id = row.user_id
      this.copy()
    },
    // 复制
    copy() {
      let text = this.code
      this.sels.forEach((e) => {
        text + e.coupon_code
      })
      var input = document.getElementById('input')
      input.value = text // 修改文本框的内容
      input.select() // 选中文本
      document.execCommand('copy') // 执行浏览器复制命令
      this.$message(
        {
          message: '复制成功',
          type: 'success',
        },
        2000
      )
    },
    getCoupon() {
      let url = '/user/commoditycoupon/queryManagerListPage'
      this.$axios
        .get(url, {
          params: {
            status: 2,
            categories: '体育运动',
          },
        })
        .then((res) => {
          this.coupon_list = res.data.data.rows
        })
    },
    // 关闭弹框
    toclose() {
      this.flag = false
      this.codeShow = false
      this.show = false
    },
    GetList() {
      let url = '/user/couponList/queryByMessage'
      // let url = '/user/userCoupon/queryManagerListPage'

      this.$axios
        .get(url, {
          params: {
            pageSize: 10,
            currentPage: this.currentPage,
            coupon_type:'尊享卡'
          },
        })
        .then((res) => {
          this.tableData = res.data.data.rows
          this.total = res.data.data.total
          this.tableData.forEach((e) => {
            let codeFive = e.coupon_code.split('').slice(0, 4).join('')
            let code = codeFive + '****'
            e.code = code
          })
        })
    },
    // 添加优惠券
    generateCoupon() {
      if (this.grantNum == '' && this.overdue == '') {
        this.$message(
          {
            message: '信息不完整',
            type: 'error',
          },
          2000
        )
      } else {
        let data = {
          number: this.grantNum,
          id: this.coupon_id,
          start_num:0,
          coupon_length: 8,
          // user_id: JSON.parse(localStorage.getItem('managerInfo')).user_id,
          user_id: "9041",
          duration: this.overdue*60*24,
          // commodity_type: this.commodity_type,
          // mobiles:this.selected_user.login_name
        }

        this.$axios.post('/user/commoditycoupon/insertCouponList', data).then((res) => {
          this.GetList()
          this.toclose()
          let message = res.data.message
          alert('添加' + message)
          this.grantNum = ''
          this.overdue = ''
          this.coupon_id = null
        })
      }
    },
    // 兑换
    lingqu(row) {
      this.show = false;
      this.codeShow = true;
      let data = {
        get_code_user_id: 11241,
        coupon_code:row.coupon_code
      };
      let url ="/user/couponList/useDiscountVolume"
      this.$axios({
        url,
        params:data,
        method:'get'
      }).then(res => {
        
        // this.onSubmit();
        this.$message(
          {
            message: "领取成功",
            type: "success"
          },
          2000
        );
      });
    },
    // lingqu(row) {
    //   this.show = false;
    //   this.codeShow = true;
    //   let data = {
    //     // id: [row.id],
    //     // user_id: row.user_id,
    //     // mobile:"18870412203",
    //     id:row.id,
    //     status:2
    //   };
    //   this.$axios.post("/user/couponList/update", data).then(res => {
    //     this.GetList();
    //     this.$message(
    //       {
    //         message: "领取成功",
    //         type: "success"
    //       },
    //       2000
    //     );
    //   });
    // },
    // 批量领取
    drawCode() {
      this.dorw = true
      //  console.log(this.sels)
      if (this.sels == '') {
        this.$message(
          {
            message: '请选中锦鲤码数据',
            type: 'error',
          },
          2000
        )
      } else {
        this.show = true
        this.id = []
        let codes = []
        this.sels.forEach((e) => {
          this.id.push(e.id)
          codes.push(e.coupon_code)
        })
        this.code = codes.join(',')
      }
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.addDialog = false
          this.updateDialog = false
          this.form = {}
          this.temp = {}
          done()
        })
        .catch((_) => {})
    },
    onSubmit() {
      // if (this.phone == '' && this.name == '') {
      //   this.GetList()
      // } else {
        this.$axios
          .get(
            '/user/couponList/queryByMessage' 
              
          )
          .then((res) => {
            console.log(res)
            this.tableData = res.data.data.rows
            this.total = res.data.data.total
            this.tableData.forEach((e) => {
              let codeFive = e.coupon_code.split('').slice(0, 4).join('')
              let code = codeFive + '****'
              e.code = code
            })
          })
      }
    // },
  },
}
</script>

<style></style>
